<template>
  <div class="login-container flex flex-column justify-center align-center">
    <h2 class="login-title">后台管理系统</h2>
    <div class="login-form">
      <a-form ref="form" :model="form">
        <h2 class="usertitle">用户登录 LOGIN</h2>
        <a-form-item>
          <a-input class="inputBox" v-model="form.username">
            <a-icon type="user" slot="prefix" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password class="inputBox" v-model="form.password">
            <a-icon type="unlock" slot="prefix" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button class="submit" type="primary" :loading="loading" @click="onSubmit">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/common"
import { setToken, set } from "@/utils/auth"
export default {
  data() {
    return {
      form: {},
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      login(this.form)
        .then((res) => {
          setToken(res.token)
          set("userInfo", res)
          setTimeout(() => {
            this.$router.push({ path: "/index" })
          }, 1)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>
<style>
.login-form {
  width: 565px;
  height: 372px;
  margin: 0 auto;
  background: url("../../assets/bg.jpg");
  padding: 40px 110px;
}

/* 背景 */
.login-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../../assets/bg.jpg");
  background-size: 100% 100%;
}

/* Log */
.login-title {
  color: #fff;
  text-align: center;
  /* margin: 150px 0; */
  font-size: 48px;
  font-family: Microsoft Yahei;
}
/* 登陆按钮 */
.submit {
  width: 100%;
  height: 45px;
  font-size: 16px;
}
/* 用户登陆标题 */
.usertitle {
  margin-bottom: 50px;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  font-family: Microsoft Yahei;
}
/* 输入框 */
.inputBox {
  /* height: 45px; */
}
/* 输入框内左边距50px */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 50px;
}
</style>
